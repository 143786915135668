@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;
    overflow: hidden;
    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        z-index: 1;
        overflow: visible;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        

        .imgContainer{  
            position: relative;
            width: 25vw;
            top: 20px;
            left: 15ch;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: visible;
            background: white;
            border-radius: 1rem;

            @include mobile {
                left: 0;
                width:fit-content;
                align-items: center;
            }
            
            img{
                border-radius: 1rem;
                opacity: 0.9;
                transition: all 0.5s ease;
                box-shadow: .8px .9px 3px grey;                
                &:hover{
                    transform:scale(1.02);
                    box-shadow: 1px 8px 20px grey;

                }

                @include mobile{
                    height: 50vh;
                    width: 100%;
                }
                
            }
        }
    }
    .right{
        z-index: 3;
        flex: 1;
        overflow: hidden;
        @include mobile {
            flex: 1;
        }

        .wrapper{
            margin-top: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include mobile{
                padding-left: 0;
                align-items: center;
                margin-top: 4em;
            }

            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 2.5rem;
                }
            }
            h2{
                font-size: 35px;
                @include mobile{
                    font-size: 1.4rem;
                }
            }
            h3{
                font-size: 30px;
                span{
                    font-size: inherit;
                    
                }
                @include mobile{
                    font-size: 20px;
                }
            }
        }

        a{
            color: inherit;
            position: absolute;
            bottom: 10px;
            left: 90%;
            animation: arrowBlink 2s infinite;
            @include mobile{
                opacity: 0;
                animation: none;
            }
        }
        @keyframes arrowBlink {
            50%{
                opacity: 20%;
            }
            100%{
            opacity: 100%;
            }
        }
    }
}
.ityped-cursor {
    font-size: 2.2rem;
    opacity: 1;
    animation: blink 0.3s infinite;
    animation-direction: alternate;
}
 
@keyframes blink {
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}



.box-area {
    z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.box-area li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	background: linear-gradient(to right, hsla(219, 90%, 62%, 0.4), hsla(268, 78%, 69%, 0.4));

    animation: animate 20s linear infinite;
	bottom: -150px;
}
.box-area li:nth-child(1) {
	left: 86%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}
.box-area li:nth-child(2) {
	left: 12%;
	width: 30px;
	height: 30px;
	animation-delay: 1.5s;
	animation-duration: 10s;
}
.box-area li:nth-child(3) {
	left: 70%;
	width: 100px;
	height: 100px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(4) {
	left: 42%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 15s;
}
.box-area li:nth-child(5) {
	left: 65%;
	width: 40px;
	height: 40px;
	animation-delay: 0s;
}
.box-area li:nth-child(6) {
	left: 15%;
	width: 110px;
	height: 110px;
	animation-delay: 3.5s;
}
@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-800px) rotate(360deg);
		opacity: 0;
	}
}