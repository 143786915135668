$mainColour: hsl(244, 94%, 54%);

$hoverColour: rgb(72, 118, 243);
$hoverColourButton:  rgba(215, 167, 247, 0.1);
$secondaryColour: white;

$glowButton: rgb(53, 123, 253);

$darkTransitionColour: calc($mainColour - 20); 

$transitionSpeed: 300ms;
$width: 768px;

@mixin mobile{
    @media (max-width: #{$width}){
        @content
    }
}