@import "global.scss";

.app{
    height: 100vh;

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        background-color: black;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        @include mobile{
            scroll-snap-type: y mandatory;
        }
        scrollbar-width: none; //For firefox
        &::-webkit-scrollbar{ //For other browsers
            display: none;
        }
        > *{
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
            scroll-snap-stop: always;

        }
    }

    @media (prefers-reduced-motion: reduce){
        *, *::before, *::after{
            animation-duration: 0.01ms
            !important;
            animation-iteration-count: 1
            !important;
            transition-duration: 0.01ms
            !important;
            scroll-behavior: auto !important;
            transition-delay: 0ms !important;
        }
    }
}