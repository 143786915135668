@import "../../global.scss";

.menu {
    width: 300px;
    height: 50vh;
    background-color: $mainColour;
    position: fixed;
    top: 70px;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all $transitionSpeed ease;
    opacity: 0;
    pointer-events: none;

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: $secondaryColour;
        width: 80%;
        li{
            margin-bottom: 20px;
            a{
                font: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover{
                font-weight: 700;
            }
        }

    }

    &.active{
        opacity: 0.9;
        pointer-events: auto;

    }
}