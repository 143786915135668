@import "../../global.scss";

.info{
    background-image: linear-gradient(to bottom right, black, rgb(0, 1, 15), rgb(0, 2, 33));
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;

    }

    .left{
        z-index: 1;
        overflow: visible;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        @include mobile{
            flex: 1;
            margin-right: 50px;
            justify-content: center;
            overflow: visible;
            margin-bottom: 3rem;
        }

        .wrapper{
            color: $secondaryColour;
            flex-direction: column;
            margin-left: 15ch;
            @include mobile{
                margin-left: 0;
            }
            h2{
                font-size: 2.5rem;
                padding-bottom: 20px;
                span{
                    color: $hoverColour;
                }
            }
            p{
                color: white;
                font-size: 1rem;
                padding-bottom: 10px;
                opacity: 0.9;
                line-height: 1.2;
            }

            ul{
                color: white;
                font-size: 0.9rem;
                opacity: 0.8;
                margin-top: 0;
                margin-left: -30px;
                columns: 2;
                width: fit-content;
                list-style-position: inside;
                list-style-type: "►";
                line-height: 1.2;


                li::marker{
                color: $hoverColour;
                }
            }
            @include mobile{
                padding-top: 30px;
                padding-left: 50px;

                h2{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 0.8rem;
                }
                li{
                    font-size: 0.7rem;
                }
            }
        }

    }

    .right{
        z-index: 1;
        overflow: visible;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile{
            flex: 0.2;
            justify-content: center;
            margin-bottom: 40px;
            }

        .card{
            margin: 10px 10px;
            margin-right: 10px;
            background:center url(tech.jpg);
            background-size: cover;
            z-index: 2;
            color: $secondaryColour;
            padding: 13rem 0 0;
            width: 35ch;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: .8px .9px 3px black;                

            @include mobile{
            padding: 5rem 0 0;
            width: 85vw;
            margin-top: 0rem;
            }
        }

        $padding: 1.5rem;
        .card-content{

            padding: $padding;
            background: linear-gradient(
            hsla(0, 0%, 0%, 0),
            hsla(0, 0%, 0%, 0.4) 10%,
            hsla(0, 0%, 0%, 1), );

           
        }
        .card-title{
            position: relative;
            width: max-content;
            overflow: visible;

        }
        .card-title::after{ /*The bar under the title*/
            content: "";
            position: absolute;
            height: 4px;
            bottom: -2px;
            opacity: 70%;
            left: calc(#{$padding} * -1);
            width: calc(100% + #{$padding});
            background: $mainColour;
            transform-origin: left;
            transition: transform 500ms ease;
        }


        .card-body{
            padding-top: $padding;
            padding-bottom: $padding;
            color: hsla(0, 0%, 100%, 0.8);
            line-height: 1.2;
        }
        .button{
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
            color: $secondaryColour;
            background-color: $mainColour;
            padding: .5em 1.25em;
            border-radius: 0.25em;

            &:focus{
                background-color: $hoverColour;
            }
            &:hover{
                background-color: $hoverColour;
            }
            
        }
        
        @media (hover){
            .card-content{
                transform: translateY(65%);
                transition: transform 500ms ease;
                
            }

            .button{
                transition: all 500ms ease;
            }

            .card{
                transition: transform 500ms ease;
                &:hover, &:focus-within{
                    transform: scale(1.05);
                    box-shadow: 1px 8px 20px black;

                    .card-content{
                        transform: translateY(0);
                        transition-delay: 500ms;
    
                    }
                    .card-content > *:not(.card-title) {
                        opacity: 1;
                        transition-delay: 1000ms;
    
                    }
                    .card-title::after{
                        transform: scaleX(1);
                    }
                }
                &:focus-within{
                    .card-content{
                        transition-duration: 0ms;
                    }
                    .card-content > *:not(.card-title) {
                        opacity: 1;
                        transition-delay: 500ms;
    
                    }
                }
            }
            
            .card-content > *:not(.card-title){
                opacity: 0;
                transition: opacity 500ms linear;
            }

            .card-title::after{
                transform: scaleX(0);
            }

        }


    }


   


}