@import "../../global.scss";

 
    .contact{        
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }
        
        overflow: hidden;
        display: grid;
        grid-template-rows: auto;
        place-items: center;
        justify-content: center;
        align-content: center;
        background-image: linear-gradient(to top right, rgb(1, 0, 33), black, rgb(0, 2, 33));
        color: $secondaryColour;

        h1{
            margin-bottom: 1rem;
        }
        
        
        .button {
          font-size: 1.5rem;
         overflow: visible;
          display: inline-block;
          cursor: pointer;
          text-decoration: none;
          color: $glowButton;
          border: $glowButton 0.125em solid;
          padding: 0.25em 1em;
          border-radius: 0.25em;
        
          text-shadow: 
          0 0 0.125em hsla(0, 0%, 100%, 0.3), 
          0 0 0.45em currentColor;
        
          box-shadow: inset 0 0 0.5em 0 , 0 0 0.5em 0 $glowButton;
        
          position: relative;
        
        
        
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: 0 0 2em 0.5em $glowButton;
          opacity: 0;
          background-color: $glowButton;
          z-index: 0;
          transition: opacity 100ms linear;
        }
        
        &:hover,
        &:focus {
        z-index: 1;
          color: black;
          text-shadow: none;
        }
        &:hover::after,
        &:focus::after {
          opacity: 1;
        }
        
    }

        .icon{
            opacity: 0;
            color: $glowButton;
            text-shadow: 
                0 0 0.125em hsla(0, 0%, 100%, 0.5),
                0 0 0.25em currentColor;

            @include mobile {
                opacity: 1;
                font-size: 2rem;
                margin: 3rem 1rem;
            }
        }
        
    }

    